import {
    GetActualNews,
    GetActualNewsVariables,
} from "gql/types/operation-result-types";
import { usePooling } from "hooks/use-pooling";
import { useQuery } from "@apollo/client";
import GET_ACTUAL_NEWS from "../gql/get-actual.gql";
import { getLanguageCode } from "components/language-provider";

export const useOtherNews = (code: string, newsId: string) => {
    const culture = getLanguageCode(code);
    const queryResult = useQuery<GetActualNews, GetActualNewsVariables>(
        GET_ACTUAL_NEWS,
        {
            ssr: true,
            variables: {
                params: {
                    culture,
                    newsId,
                },
            },
        },
    );

    const status = g(
        queryResult,
        "data",
        "partnersProgram",
        "news",
        "getActualNews",
        "statusID",
    );
    const otherNews = g(
        queryResult,
        "data",
        "partnersProgram",
        "news",
        "getActualNews",
        "news",
    );

    usePooling(queryResult, status, 500);

    return {
        status,
        otherNews,
        error: queryResult.error,
        loading: queryResult.loading,
    };
};
