import { Briefcase } from "@styled-icons/fa-solid/Briefcase/Briefcase";
import { UserCircle } from "@styled-icons/fa-solid/UserCircle/UserCircle";
import { AnimateWhenInViewport } from "components/components-common/animate";
import { Access } from "components/check-roles/access";
import { exceptRoles, auth } from "components/check-roles/rules";
import { useDefinedContext } from "hooks/use-context-exist";
import { useTranslation } from "react-i18next";
import { DefaultColoredLinkButton } from "styled/link";
import { PartnersProgramGeneralInformationContext } from "views";
import {
    WelcomeBtn,
    WelcomeList,
    WelcomePanelSC,
    WelcomeTitle,
} from "./styled";
import { PARTNERS_MELBETRU } from "./../../../../../../server/partner-programs";
import { usePartnersProgram } from "hooks/use-partners-program-id";
import { SubTitle } from "./subtitle";
import {
    IButtonGradientProps,
    SingUpButton,
} from "components/header/components/sing-up-button";
interface IProps {
    textColor: string;
    bgColor: string;
    buttonGradientProps?: IButtonGradientProps;
}

export const WelcomePanel = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { textColor, bgColor } = props;
    const partnersProgramId = usePartnersProgram()?.programId;
    const { landingMainPage, agentSiteInformation } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    if (!landingMainPage) {
        return null;
    }

    const { comission } = landingMainPage;

    const [intropart1, intropart2] = landingMainPage.welcomeInfo.title;
    const list = landingMainPage.welcomeInfo.list[0].listItem;

    return (
        <WelcomePanelSC textColor={textColor} bgColor={bgColor}>
            <WelcomeTitle>
                {intropart1}
                <br />
                {intropart2}
            </WelcomeTitle>
            {partnersProgramId === PARTNERS_MELBETRU && <SubTitle />}
            <WelcomeList>
                {list.map(({ message, formatKey }, i) => {
                    switch (formatKey) {
                        case "CompanyName":
                            return (
                                <li key={i}>
                                    {format(message, {
                                        CompanyName:
                                            agentSiteInformation.companyName,
                                    })}
                                </li>
                            );
                        case "comission":
                            return (
                                <li key={i}>
                                    {format(message, {
                                        comission,
                                    })}
                                </li>
                            );
                        default:
                            return <li key={i}>{message}</li>;
                    }
                })}
            </WelcomeList>
            <WelcomeBtn>
                <Access guards={[exceptRoles([auth])]}>
                    <AnimateWhenInViewport
                        animateType="fadeInLeftShort"
                        animateDelay={1500}
                    >
                        <SingUpButton
                            id="welcome_sign-up"
                            {...props.buttonGradientProps}
                        >
                            <UserCircle size={18} />
                            {landingMainPage.welcomeInfo.signUpBtnText.map(
                                text => text,
                            )}
                        </SingUpButton>
                    </AnimateWhenInViewport>
                </Access>
                <Access name="presentation">
                    <AnimateWhenInViewport
                        animateType="fadeInRightShort"
                        animateDelay={1500}
                    >
                        <DefaultColoredLinkButton
                            href={"/lapi/promotion/presentation.pdf"}
                            target="_blank"
                        >
                            <Briefcase size={18} />
                            {__("Презентация")}
                        </DefaultColoredLinkButton>
                    </AnimateWhenInViewport>
                </Access>
            </WelcomeBtn>
        </WelcomePanelSC>
    );
}) as React.ComponentType<IProps>;
