import { AnimateWhenInViewport } from "components/components-common/animate";
import { useDefinedContext } from "hooks/use-context-exist";
import { Wrapper } from "styled/layouts";
import { PartnersProgramGeneralInformationContext } from "views";
import { MobileBackgroundImage, WrapperFullWidthFirstScreen } from "./styled";

interface IProps {
    children: JSX.Element;
}

export const FirstScreen = React.memo(({ children }: IProps) => {
    const { landingMainPage } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    if (!landingMainPage) {
        return null;
    }

    return (
        <WrapperFullWidthFirstScreen>
            <Wrapper>
                <AnimateWhenInViewport
                    animateType="bounceInRight"
                    animateDelay={500}
                >
                    {children}
                </AnimateWhenInViewport>
            </Wrapper>
            <MobileBackgroundImage src="/config-files/main_mobile" alt="" />
        </WrapperFullWidthFirstScreen>
    );
}) as React.ComponentType<IProps>;
