import React from "react";
import Helmet from "react-helmet-with-visor";
import { useTranslation } from "react-i18next";
import { WrapperFullWidth, Wrapper, InfoPageWrapper } from "styled/layouts";
import { PrivacyPolicyInformation } from "./privacy-policy-information";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const PrivacyPolicy = React.memo((props: IProps) => {
    const [__] = useTranslation();
    return (
        <WrapperFullWidth>
            <Helmet>
                <title>{__("Политика конфиденциальности")}</title>
            </Helmet>
            <Wrapper>
                <InfoPageWrapper>
                    <PrivacyPolicyInformation />
                </InfoPageWrapper>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
