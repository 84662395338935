import { media } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { Wrapper, WrapperFullWidth } from "styled/layouts";
import {
    BETANDYOU_AFFILIATES,
    _1XCASINO_PARTNERS,
} from "server/partner-programs";

export const MobileBackgroundImage = styled.img`
    ${media.sm`
        display: none;
    `};
    width: 100%;
`;

/*
    !TODO: когда вернем старый баннер заменить (файл main_old.jpg -> main.jpg),
*/
export const WrapperFullWidthFirstScreen = styled(WrapperFullWidth)`
    /* 
        Компоненты для анимации своих детей могут выкидывать их за пределы экрана.
        Без "overflow-x: hidden;" вёрстка на некоторых устройствах может ломаться.
    */
    overflow: hidden visible;
    background-image: url("/config-files/main");
    ${props =>
        props.theme.partnersProgramId === _1XCASINO_PARTNERS &&
        css`
            ${media.smMax`
                background-image: none
            `}
        `}
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 720px;
    ${props =>
        props.theme.dir === "rtl"
            ? css`
                  transform: scaleX(-1);
              `
            : css`
                  transform: scale(1);
              `}
    ${props => css`
        ${media.mdMax`
            min-height: 540px;
            padding: 30px 10px;
            ${
                props.theme.partnersProgramId === BETANDYOU_AFFILIATES &&
                css`
                    /**
                        !TODO: когда вернем старый баннер параметр min-height нужно будет удалить
                    */
                    min-height: 670px;
                    background-position: 0 30%;
                    ${media.smMax`
                        min-height: 540px;
                    `};
                `
            }
        `};
    `}

    ${Wrapper} {
        position: relative;
        ${props =>
            props.theme.dir === "rtl"
                ? css`
                      transform: scaleX(-1);
                  `
                : css`
                      transform: scale(1);
                  `}
    }
`;
