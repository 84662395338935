/* eslint-disable @typescript-eslint/no-unused-vars */
import { GetTestimonials } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_TESTIMONIALS = gql`
    query GetTestimonials {
        partnersProgram {
            testimonials {
                id
                author
                description
                href
                image
            }
        }
    }
`;

export const useTestimonials = () => {
    const { data, loading, error } = useQuery<GetTestimonials>(
        GET_TESTIMONIALS,
        { ssr: true },
    );

    return {
        testimonials: data?.partnersProgram.testimonials || [],
        loading,
        error,
    };
};
